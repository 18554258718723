import * as React from "react"
import { Link } from "gatsby"
import "../css/main.scss"
import {Helmet} from "react-helmet";
import StandardHeadContent from "../components/standardHeadContent";
import Menu from "../components/menu";
import Cookies from "universal-cookie";
import {useEffect, useState} from "react";
import Axios from "axios";

const NotFoundPage = () => {

    const cookies = new Cookies();
    const [lang,setLang] = useState(cookies.get("lang") ||"no");

    const changeLang = (lang) => {
        cookies.set("lang",lang);
        setLang(lang);
    }

    useEffect(() => {
        Axios.get("/log.php?page=error_"+lang);
    },[])

  return (
      <main>
          <Helmet>
              <title>Nina & Knut - 11. juli 2022</title>
              <StandardHeadContent/>
          </Helmet>

          <Menu lang={lang} changeLang={changeLang}/>

          <div id="duogram"></div>

              <>
                  <h1>Ikke funnet / Not found</h1>
                  <p>
                      Innholdet finnes ikke. Gå tilbake til <Link to={"/"}>forsiden</Link>.
                  </p>
                  <p>
                      The content was not found. Go to the <Link to={"/"}>front page</Link>.
                  </p>

              </>

      </main>
  )
}

export default NotFoundPage

